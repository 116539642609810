"use client";

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "./formFields/Input";
import ButtonPanel from "./ButtonPanel";
import Link from "next/link";
import { useSession } from "utils/hooks";

type Inputs = {
	email: string;
	password: string;
};

type Props = {
	dashboardSlug: string;
};

export const LoginForm: React.FC<Props> = ({ dashboardSlug }) => {
	const { handleSubmit, setError, control } = useForm<Inputs>();
	const { loading: sessionLoading, session, login } = useSession();
	const router = useRouter();

	useEffect(() => {
		if (!sessionLoading && session) {
			if (session.isLoggedIn) {
				router.push(`/${dashboardSlug}`);
			}
		}
	}, [sessionLoading, session]);

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		setIsLoading(true);

		let response = await login(data.email, data.password);
		if (response["error"]) {
			setError("email", {
				type: "invalid",
				message: "Invalid email or password",
			});
		} else {
			window.location.href = `/${dashboardSlug}`;
		}

		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-start gap-10">
			<Input label="Email" type="email" formKey="email" control={control} />
			<Input label="Password" type="password" formKey="password" control={control} />
			<div className="grid w-full gap-2">
				<ButtonPanel text={"Login"} isLoading={isLoading} />
				<Link href="/forgot-password" className="text-right">
					Forgot your password?
				</Link>
			</div>
		</form>
	);
};
